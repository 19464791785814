<template>
  <div>
    <apexchart
      type="line"
      :height="height"
      :width="width"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    height: {
      type: [String, Number],
      default() {
        return 400;
      },
    },
    width: {
      type: [String, Number],
      default() {
        return 500;
      },
    },
  },
  data() {
    return {
      series: [
        {
          name: "Cost",
          data: [], // Your data points
        },
      ],
      chartOptions: {
        chart: {
          width: '100%',
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '10px', // Smaller font size for readability
          },
          offsetY: -200, // Offset labels slightly to avoid overlap
        },
        stroke: {
          curve: "smooth",
          width: 2,
          colors: ["#d3bd95"],
        },
        markers: {
          size: 5,
          colors: ["#FFAE20"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 8,
          },
        },
        tooltip: {
          theme: "light",
          marker: {
            fillColors: ["#FFAE20"],
          },
          y: {
            formatter: (value) => {
              // Format value with commas for thousands
              return value.toLocaleString();
            },
          },
          x: {
            formatter: (val, opts) => {
              // Access start and end date from data
              const startDate = this.data[opts.dataPointIndex].period_start_date;
              const endDate = this.data[opts.dataPointIndex].period_end_date;

              // Format dates with time using moment.js (if imported)
              const startFormatted = moment(startDate).format("MM/DD");
              const endFormatted = moment(endDate).format("MM/DD");

              return `${startFormatted} - ${endFormatted}`;
            },
          },
        },
        xaxis: {
          categories: [], // X-axis categories
          labels: {
            rotate: -45, // Rotate labels to make them more visible
            style: {
              fontSize: '10px', // Font size for x-axis labels
            },
          },
        },
        yaxis: {
          show: false,
          labels: {
            formatter: (value) => {

              return value;
            },
            style: {
              fontSize: '10px', // Font size for y-axis labels
            },
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
      },
    };
  }

  ,
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        if (newData.length) {
          this.updateChartData(newData);
        }
      }
    }
  },

  methods: {
    xAxisValues(start) {
      const startDate = new Date(start);
      const endDate = new Date();
      let dateFormat = "MMM DD";

      if (startDate.getFullYear() !== endDate.getFullYear()) {
        dateFormat = "YYYY-MMM DD";
      }

      return moment(start).format(dateFormat);
    },

    updateChartData(data) {
      // Set the series data for y-axis (total_electric_cost)
      this.series = [
        {
          name: "Cost",
          // data: data.map(item => item.total_electric_cost)
          data: data.map(item => parseFloat(item.total_electric_cost.replace(/,/g, '')))

        }
      ];

      // Format the x-axis categories using period_start_date
      this.chartOptions.xaxis.categories = data.map(item => this.xAxisValues(item.period_start_date));
    }
  }
};
</script>